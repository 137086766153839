<template>
  <el-main>
    <el-tabs v-model="activeConfig">
      <el-tab-pane label="首页分享" name="first"></el-tab-pane>
      <el-tab-pane label="商品分享" name="second"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <div class="left" v-if="activeConfig === 'first'">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="卡片推荐语：" prop="name">
            <el-input v-model="ruleForm.name" placeholder="我是店铺名称"></el-input>
          </el-form-item>
          <el-form-item label="分享卡片效果：">
            <el-radio-group v-model="ruleForm.is_set_the_picture">
              <el-radio :label="0">默认效果</el-radio>
              <el-radio :label="1">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div v-if="ruleForm.is_set_the_picture" class="tips ml120">分享卡片尺寸为5:4,建议图片大小不超过500kb</div>
        <div v-else class="tips ml120">默认效果为微信自带效果，请以实际分享效果为准</div>
        <div v-if="ruleForm.is_set_the_picture" class="ml120">
          <ReadyUploadSource
            :showStyle="{
              width: '200px',
              height: '160px',
            }"
            :path="ruleForm.picture"
            @removeThis="() => (ruleForm.picture = '')"
            @getSource="val => (ruleForm.picture = val.path)"
          ></ReadyUploadSource>
        </div>
      </div>
      <div class="left" v-if="activeConfig === 'second'">
        <el-form :model="GoodsShareInfo" status-icon ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="自定义分享：">
            <el-switch v-model="GoodsShareInfo.is_open" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="店铺logo：">
            <ReadyUploadSource
              :showStyle="{
                width: '170px',
                height: '40px',
              }"
              :path="GoodsShareInfo.custom_logo"
              @removeThis="() => (GoodsShareInfo.custom_logo = '')"
              @getSource="val => (GoodsShareInfo.custom_logo = val.path)"
            ></ReadyUploadSource>
            <div class="tips">建议尺寸170*40px，图片大小不超过1M</div>
          </el-form-item>
          <el-form-item label="商品宣传语：">
            <el-input v-model="GoodsShareInfo.custom_title" :maxlength="15"></el-input>
            <span class="tips" style="margin-left: 20px">名称不超过15个汉字</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <div class="cardName" v-if="activeConfig === 'first'">{{ ruleForm.name || '' }}</div>
        <img v-if="activeConfig === 'first' && ruleForm.is_set_the_picture && ruleForm.picture" :src="ruleForm.picture" alt="" />
        <div v-if="activeConfig === 'second'" class="goodsShare" :style="{ backgroundImage: `url(${imgUrlHead}goodsShare.png)` }">
          <img class="logo" v-if="GoodsShareInfo.custom_logo" :src="GoodsShareInfo.custom_logo" alt="" />
          <div class="custom_title">{{ GoodsShareInfo.custom_title }}</div>
        </div>
      </div>
    </div>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      imgUrlHead,
      activeConfig: 'first',
      ruleForm: {
        name: '',
        is_set_the_picture: 0,
        picture: '',
      },
      GoodsShareInfo: {
        is_open: 0,
        custom_logo: '',
        custom_title: '',
      },
      rules: {
        name: { required: true, message: '请输入卡片推荐语', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getShareInfo();
    this.getShareGoods();
  },
  methods: {
    // 点击保存按钮
    preservation() {
      if (this.activeConfig == 'first') {
        // 主页分享
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.ruleForm.is_set_the_picture && !this.ruleForm.picture) {
              this.$message.warning('自定义卡片必须上传图片');
              return;
            }
            this.editShareInfo();
          }
        });
      } else if (this.activeConfig == 'second') {
        // 商品分享
        if (this.GoodsShareInfo.is_open) {
          if (!this.GoodsShareInfo.custom_logo || !this.GoodsShareInfo.custom_title) {
            this.$message.warning('请将商品分享配置填写完整');
            return;
          }
        }
        this.editShareGoods();
      }
    },
    // 获取首页分享配置
    getShareInfo() {
      this.$axios.post(this.$api.set.videoNumber.getShareInfo).then(res => {
        if (res.code === 0) {
          if (res.result) {
            this.ruleForm = res.result;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 调用修改接口
    editShareInfo() {
      let obj = {
        name: this.ruleForm.name,
        is_set_the_picture: this.ruleForm.is_set_the_picture,
      };
      if (this.ruleForm.is_set_the_picture) {
        obj.picture = this.ruleForm.picture;
      }
      this.$axios.post(this.$api.set.videoNumber.editShareInfo, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('主页分享编辑成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取分享商品设置
    getShareGoods() {
      this.$axios.post(this.$api.retail.GoodsShareInfo).then(res => {
        if (res.code == 0) {
          if (res.result) this.GoodsShareInfo = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editShareGoods() {
      let obj = {
        is_open: this.GoodsShareInfo.is_open,
        custom_logo: this.GoodsShareInfo.custom_logo,
        custom_title: this.GoodsShareInfo.custom_title,
      };
      this.$axios.post(this.$api.retail.GoodsShareEdit, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('商品分享编辑成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;

  .content {
    display: flex;
    justify-content: space-between;
  }
  .left {
    min-width: 700px;
  }
  .el-input {
    width: 400px;
  }
  .tips {
    font-size: 12px;
    color: #9b9b9b;
    margin-bottom: 20px;
  }
  .ml120 {
    margin-left: 120px;
  }
  .right {
    flex-shrink: 0;
    width: 340px;
    height: 677px;
    background-image: url('../../../assets/image/site/shareInfoBg.png');
    background-size: 100% 100%;
    position: relative;
    margin-right: 200px;
    .cardName {
      width: 230px;
      position: absolute;
      top: 170px;
      left: 80px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > img {
      width: 230px;
      height: 184px;
      position: absolute;
      top: 200px;
      left: 80px;
    }
    .goodsShare {
      width: 224px;
      height: 184px;
      background-size: 100% 100%;
      position: absolute;
      top: 200px;
      left: 80px;
      .logo {
        width: 85px;
        height: 20px;
        position: relative;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
      .custom_title {
        position: relative;
        top: 16px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
